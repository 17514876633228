<template lang="pug">
  b-row.mb-5
    b-col
      categories-form(:action='action')
</template>

<script>
import CategoriesForm from '@views/container/manager/globals/categories/Form'

export default {
  name: 'manager-categories-new',
  components: {
    CategoriesForm,
  },
  props: {
    action: String,
  },
}
</script>
